<template>
  <div class="map_type_container">
    <div
      id="wx_map"
      class="map_type_btn"
      v-bind:class="{ map_type_btn_selected: wx_selected }"
      v-on:click="switchMapType(0)"
    >
      卫星地图
    </div>
    <div
      id="pm_map"
      class="map_type_btn"
      v-bind:class="{ map_type_btn_selected: pm_selected }"
      v-on:click="switchMapType(1)"
    >
      平面地图
    </div>
  </div>
</template>

<script>
export default {
  name: "mapTypeSwitch",
  emits: ["switch-map-type"],
  data() {
    return {
      map_type: 0,
      wx_selected: true,
      pm_selected: false,
    };
  },
  methods: {
    switchMapType(type) {
      this.map_type = type;
      switch (type) {
        case 1:
          this.wx_selected = false;
          this.pm_selected = true;
          break;
        case 0:
          this.wx_selected = true;
          this.pm_selected = false;
          break;
      }
      this.$emit("switch-map-type", type);
    },
  },
};
</script>

<style scoped>
.map_type_container {
  border: 3px solid rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.4);
}

.map_type_btn {
  float: left;
  color: #fff;
  font-size: 14px;
  padding: 5px 15px;
  margin-right: 1px;
  background: rgba(0, 60, 136, 0.5);
  cursor: pointer;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.4);
}

.map_type_btn_selected {
  background: rgba(0, 60, 136, 0.8);
}
</style>