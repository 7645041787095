<template>

    <div class="container">
        <olmap style="width: 100%; height: 100%" edit map_id="ov_map" ref="ov_olmap" @init="init"></olmap>
    </div>
</template>
<script>
import olmap from "@/components/map/olmap.vue";

import { Feature } from "ol";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Style, Icon } from "ol/style";
import { transform, fromLonLat } from "ol/proj";
import { toStringXY } from "ol/coordinate";
import { Point } from "ol/geom";
import { Pointer as PointerInteraction } from "ol/interaction";


export default {
    components: { olmap },
    emits: ['change'],
    props: {
        drag: {
            type: Boolean,
            default: false,
        }
    },
    setup() {

    },
    data() {
        return {
            location: undefined,
            map: undefined,
            iconFeature: undefined,
            iconDrag: undefined,
            /**
            * @type {import("../src/ol/coordinate.js").Coordinate}
             * @private
           */
            coordinate_: null,

            /**
             * @type {string|undefined}
             * @private
             */
            cursor_: "move",

            /**
             * @type {Feature}
             * @private
             */
            feature_: null,

            /**
             * @type {string|undefined}
             * @private
             */
            previousCursor_: undefined,
        }
    },
    methods: {
        init() {
            this.map = this.$refs.ov_olmap.map;
            //定位
            this.generateIconLayer();
            this.map.addLayer(this.iconLayer);
            if (this.iconLayer) {
                this.iconFeature = this.genereateLocationFeature();
                this.iconLayer.getSource().addFeature(this.iconFeature);
            }

            if (this.drag) {
                //拖拽事件
                if (this.iconDrag == undefined) {
                    this.initMapDrag();
                }
                this.map.addInteraction(this.iconDrag);
            }
        },
        showLocation(location) {
            var point = new Point(
                fromLonLat(JSON.parse("[" + location + "]"))
            );
            this.iconFeature.setGeometry(point);
            this.$refs.ov_olmap.toCenter(location);
        },
        generateIconLayer() {
            this.iconLayer = new VectorLayer({
                source: new VectorSource(),
                zIndex: 2,
                updateWhileAnimating: true,
                updateWhileInteracting: true,
            });
        },
        genereateLocationFeature() {
            var iconFeature = new Feature({

                name: "",
            });
            iconFeature.setStyle(
                new Style({
                    image: new Icon({
                        size: [63, 100],
                        anchor: [0.5, 1],
                        src: require("../../../assets/location.png"),
                        scale: 0.4,
                    }),
                })
            );
            return iconFeature;
        },
        initMapDrag() {
            var self = this;
            this.iconDrag = new PointerInteraction({
                handleDownEvent: self.handleDownEvent,
                handleDragEvent: self.handleDragEvent,
                handleMoveEvent: self.handleMoveEvent,
                handleUpEvent: self.handleUpEvent,
            });
        },
        /**
         * @param {import("../src/ol/MapBrowserEvent.js").default} evt Map browser event.
         * @return {boolean} `true` to start the drag sequence.
         */
        handleDownEvent(evt) {
            const map = evt.map;

            const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                return feature;
            });

            if (feature) {
                this.coordinate_ = evt.coordinate;
                this.feature_ = feature;
            }

            return !!feature;
        },

        /**
         * @param {import("../src/ol/MapBrowserEvent.js").default} evt Map browser event.
         */
        handleDragEvent(evt) {
            const deltaX = evt.coordinate[0] - this.coordinate_[0];
            const deltaY = evt.coordinate[1] - this.coordinate_[1];

            const geometry = this.feature_.getGeometry();
            geometry.translate(deltaX, deltaY);

            this.coordinate_[0] = evt.coordinate[0];
            this.coordinate_[1] = evt.coordinate[1];
        },

        /**
         * @param {import("../src/ol/MapBrowserEvent.js").default} evt Event.
         */
        handleMoveEvent(evt) {
            if (this.cursor_) {
                const map = evt.map;
                const feature = map.forEachFeatureAtPixel(
                    evt.pixel,
                    function (feature) {
                        return feature;
                    }
                );
                const element = evt.map.getTargetElement();
                if (feature) {
                    if (element.style.cursor != this.cursor_) {
                        this.previousCursor_ = element.style.cursor;
                        element.style.cursor = this.cursor_;
                    }
                } else if (this.previousCursor_ !== undefined) {
                    element.style.cursor = this.previousCursor_;
                    this.previousCursor_ = undefined;
                }
            }
        },

        /**
         * @return {boolean} `false` to stop the drag sequence.
         */
        handleUpEvent() {
            var coordinate = transform(this.coordinate_, "EPSG:3857", "EPSG:4326");
            var coordStr = toStringXY(coordinate, 8);
            this.location = coordStr;
            this.$emit("change", this.location);
            this.coordinate_ = null;
            this.feature_ = null;
            return false;
        },
    }
}
</script>

<style scoped>
.container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}
</style>