<template>
  <div v-loading="loading" element-loading-text="loadingText">
    <div :id="map_id" class="map"></div>
    <map-type-switch class="switch-container" @switch-map-type="switchMaptype"></map-type-switch> 
  </div>
</template>
<script>
import "ol/ol.css";
import { Feature, Map, View } from "ol";
import { toStringXY } from "ol/coordinate";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import MapTypeSwitch from "./components/MapTypeSwitch.vue";

import { Vector as VectorSource, /*Cluster*/ } from "ol/source";
import { Vector as VectorLayer, Heatmap as HeatmapLayer } from "ol/layer";
import { Fill, Stroke, Style, Icon, Text } from "ol/style";
import Overlay from "ol/Overlay";
// import { KML } from "ol/format";
import { fromLonLat, transform } from "ol/proj";
import { Polygon, Circle, Point } from "ol/geom";
// import { GeoJSON } from "ol/format";
import { FullScreen, ScaleLine } from "ol/control";
import { getArea } from "ol/sphere";
import { defaults as defaultControls } from "ol/control";

// import { getMapIconUrl, getIconList } from "@/data/data";

// import { colorToRGBA } from "@/utils/mapUtils";

// import { ExportMap, MapMeasureUtils } from "@/components/map";

// import domtoimage from "dom-to-image";
// import { saveAs } from "file-saver";

// import { ElLoading } from "element-plus";

export default {
  name: "olmap",
  emits: ["init"],
  props: {
    edit: {
      type: Boolean,
      required: true,
      defalut: false,
    },
    map_id: {
      type: String,
      required: true,
      default: "map",
    },
    display_config: {
      type: Object,
      required: false,
      default: () => {
        return {
          alarm_show_name: 1,
          alarm_show_coverage: 1,
          coverage_radius: 500,
          alarm_coverage_style: 0,
          alarm_coverage_data: 1,
          area_region_line_size: 2,
          area_region_color: "#3399CC",
          alarm_icon_scale: 50,
        };
      },
    },
    show_menu: {
      type: Boolean,
      required: false,
      defalut: false,
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    console.log(" ol map ------------setup");
    console.log(props);
  },
  components: { MapTypeSwitch },
  data() {
    return {
      map: undefined,
      tileLayer: undefined,
      mapType: 0,
      center: [112.9389112, 28.22789212],
      zoom: 12,
      minZoom: 8,
      maxZoom: 18,
      iconLayer: undefined,
      areaRegionLayer: undefined,
      clusterLayer: undefined,
      heatLayer: undefined,
      loading: false,
      loadingText: "正在加载数据....",
      measureRegionLayer: undefined,
      measureTooltipElement: undefined,
      measureTooltip: undefined,
      overlayList: [],
      token: '9aab93761fff982c09f825592a730755',
    };
  },
  mounted() {
    console.log("map_id:" + this.map_id + ",edit:" + this.edit);

    this.tileLayer = new TileLayer({
      source: new XYZ({
        url: this.getMapTilesUrl(this.mapType),
        crossOrigin: "anonymous",
      }),
    });

    this.noteLayer = new TileLayer({
      source: new XYZ({
        url: this.getMapNoteTilesUrl(this.mapType),
        crossOrigin: "anonymous",
      }),
    });

    var map_config = this.$store.getters.map_config;
    if (map_config) {
      if (Object.prototype.hasOwnProperty.call(map_config, 'defaultcoord')) {
        this.center = JSON.parse("[" + map_config.defaultcoord + "]");
      }

      if (Object.prototype.hasOwnProperty.call(map_config, 'zoom')) {
        this.zoom = parseInt(map_config.zoom);
      }

      if (Object.prototype.hasOwnProperty.call(map_config, 'minZoom')) {
        this.minZoom = parseInt(map_config.minZoom);
      }

      if (Object.prototype.hasOwnProperty.call(map_config, 'maxZoom')) {
        this.maxZoom = parseInt(map_config.maxZoom);
      }
      if (Object.prototype.hasOwnProperty.call(map_config, 'tianditu_token')) {
        this.token = map_config.tianditu_token;
      }
    }
    console.log(this.minZoom + "," + this.maxZoom);

    this.map = new Map({
      target: this.map_id,
      controls: defaultControls().extend([new ScaleLine()]),
      layers: [this.tileLayer],
      view: new View({
        center: transform(this.center, "EPSG:4326", "EPSG:3857"), //
        zoom: this.zoom,
        maxZoom: this.maxZoom,
        minZoom: this.minZoom,
      }),
    });

    console.log("fullscreen===2=>" + this.fullScreen);
    if (this.fullScreen) {
      this.map.addControl(new FullScreen());
    }
    console.log(this.map);

    this.$emit("init");


  },
  methods: {
    getMapTilesUrl(type) {
      this.type = this.mapType;
      //https://map.kdthe.com/googlemaps/satellite/{z}/{x}/{y}.jpg
      // https://map.kdthe.com/googlemaps/roadmap/{z}/{x}/{y}.png
      // switch (type) {
      //   case 0:  //卫星图
      //     return 'http://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=' + this.token;

      //   case 1: //平面图
      //     return 'http://t0.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=' + this.token;
      //   case 2: //地形图
      //     return 'http://t0.tianditu.gov.cn/ter_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=ter&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=' + this.token;

      // }

      switch (type) {
        case 0:  //卫星图
          return 'https://map.kdthe.com/googlemaps/satellite/{z}/{x}/{y}.jpg';

        case 1: //平面图
          return 'http://wprd02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=7';
        
      }
    },
    getMapNoteTilesUrl(type) {
      this.type = this.mapType;
      switch (type) {
        case 0://卫星图
          return 'http://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=' + this.token;
        case 1://平面图
          return 'http://t0.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=' + this.token;
        case 2:   //地形图
          return 'http://t0.tianditu.gov.cn/cta_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cta&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=' + this.token;

      }
    },

    switchMaptype(type) {
      this.mapType = type;
      this.tileLayer.getSource().setUrl(this.getMapTilesUrl(type));
      // this.noteLayer.getSource().setUrl(this.getMapNoteTilesUrl(type));

      if (this.areaRegionLayer) {
        this.areaRegionLayer.getSource().changed();
      }
      if (this.iconLayer) {
        this.iconLayer.getSource().changed();
      }
    },
    handleOnClick(e) {
      var p = e.coordinate;
      console.log(p);
    },
    // getIconUrl(icon) {
    //   return getMapIconUrl(icon);
    // },
    generateIconLayer() {
      var self = this;
      var styleFunction = function (feature) {
        // let type = feature.getGeometry().getType();
        let name = feature.get("name");
        // console.log(type + "," + name);
        if (name == "mapIconCoverage") {
          let style = new Style({
            fill: new Fill({
              color:
                parseInt(self.mapType) == 0
                  ? "rgba(241, 186, 49, 0.55)"
                  : "rgba(241, 186, 49, 0.55)",
            }),
            stroke: new Stroke({
              color: parseInt(self.mapType) == 0 ? "#F1B931" : "#F1B931",
              width: 1,
            }),
          });
          return style;
        }

        let icon = JSON.parse(feature.getId());
        var zoom = self.map.getView().getZoom();
        let scale = icon.scale;
        if (self.display_config.alarm_icon_scale > 10) {
          scale = self.display_config.alarm_icon_scale / 100;
        }
        scale = (scale * zoom) / 22;
        var style = new Style({
          image: new Icon({
            size: icon.size,
            anchor: icon.anchor,
            src: self.getIconUrl(icon),
            scale: scale,
          }),
        });

        if (parseInt(self.display_config.alarm_show_name) == 1) {
          let offset_x = Object.prototype.hasOwnProperty.call(icon, "offset_x")
            ? icon.offset_x
            : -1;
          if (offset_x == -1) {
            offset_x =
              (icon.size[0] * icon.scale) / 2 -
              icon.size[0] * icon.scale * icon.anchor[0];
          }
          let offset_y = Object.prototype.hasOwnProperty.call(icon, "offset_y")
            ? icon.offset_y
            : -1;
          if (offset_y == -1) {
            offset_y = -(icon.size[1] * icon.anchor[1] * icon.scale) - 10;
          }
          // console.log(icon);
          var text = new Text({
            text: icon.name,
            font: "normal 12px 'Microsoft YaHei'",
            fill: new Fill({
              color: "#edffed",
            }),
            stroke: new Stroke({
              color: "#444444",
              width: 2,
            }),
            offsetX: offset_x,
            offsetY: offset_y,
          });
          style.setText(text);
        }

        return style;
      };
      this.iconLayer = new VectorLayer({
        source: new VectorSource(),
        zIndex: 2,
        updateWhileAnimating: true,
        updateWhileInteracting: true,
        style: styleFunction,
      });
    },
    generateIconFeature(icon, coordinates) {
      let feature = new Feature({
        geometry: new Point(fromLonLat(JSON.parse("[" + coordinates + "]"))),
        name: "mapIcon",
      });
      icon["coordinates"] = coordinates;
      feature.setId(JSON.stringify(icon));

      return feature;
    },

    /**
     * 创建六边形坐标
     * @param center 中心点
     * @param radius  半径 单位米
     */
    createHexagonGeometry(center, radius) {
      let newCoordinates = [];
      let numPoints = 6;
      for (let i = 0; i < numPoints; ++i) {
        let angle = (i * 2 * Math.PI) / numPoints; //计算弧度
        let offsetX = radius * Math.cos(angle); // x 值
        let offsetY = radius * Math.sin(angle); //y 值
        newCoordinates.push([center[0] + offsetX, center[1] + offsetY]);
      }
      newCoordinates.push(newCoordinates[0].slice());
      return new Polygon([newCoordinates]);
    },
    generateCoverageFeature(icon, coordinates) {
      let radius = Number(icon.coverage_radius) * (100 / 88);
      let center = fromLonLat(JSON.parse("[" + coordinates + "]"));
      let geometry = new Circle(center, radius);

      switch (parseInt(this.display_config.alarm_coverage_style)) {
        case 0: //圆形
          geometry = new Circle(center, radius);
          break;
        case 1: //六边形
          geometry = this.createHexagonGeometry(center, radius);
          break;
      }
      let feature = new Feature({
        geometry: geometry,
        name: "mapIconCoverage",
      });
      feature.setId("coverage-" + JSON.stringify(icon));

      return feature;
    },

    getFeatureAtPixel(pixel) {
      let feature = this.map.forEachFeatureAtPixel(pixel, function (feature) {
        return feature;
      });
      return feature;
    },
    toCenter(coordinate, zoom) {
      this.map
        .getView()
        .setCenter(
          transform(
            JSON.parse("[" + coordinate + "]"),
            "EPSG:4326",
            "EPSG:3857"
          )
        );
      if (zoom) {
        this.map.getView().setZoom(zoom);
      }
    },
    mapZoomToExtent(layers) {
      if (!layers) {
        return;
      }
      let extents = [];
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].getSource().getFeatures().length > 0) {
          extents.push(layers[i].getSource().getExtent());
        } else {
          console.debug("features size is zero");
        }
      }

      if (extents.length <= 0) return;

      let box = [
        Number.POSITIVE_INFINITY,
        Number.POSITIVE_INFINITY,
        Number.NEGATIVE_INFINITY,
        Number.NEGATIVE_INFINITY,
      ];
      let extent = extents.reduce(function (prev, coord) {
        return [
          Math.min(coord[0], prev[0]),
          Math.min(coord[1], prev[1]),
          Math.max(coord[2], prev[2]),
          Math.max(coord[3], prev[3]),
        ];
      }, box);
      let that = this;
      this.map.getView().fit(extent, {
        size: that.map.getSize(),
        duration: 800,
      });
    },
    initAreaRegionLayer() {
      var self = this;
      var styleFunction = function (feature) {
        var name = feature.getProperties().name;
        // let color = feature.getProperties().fill;
        let type = feature.getGeometry().getType();
        let style;

        switch (type) {
          default:
          case "MultiPolygon":
            style = new Style({
              stroke: new Stroke({
                // color: parseInt(self.mapType) == 0 ? "#3399CC" : "#339999",\
                color: self.display_config.area_region_color,
                width: self.display_config.area_region_line_size,
              }),
            });
            // var zoom = self.map.getView().getZoom();

            // var opacity = 0.01;

            // if (zoom > 12) {
            //   opacity = 0;
            // }
            // var fill = new Fill({
            //   color: colorToRGBA(color, opacity),
            // });
            // style.setFill(fill);

            return style;
          case "Point":
            style = Style({});
            var text = Text({
              font: 'normal 12px "Microsoft YaHei"',
              fill: Fill({
                color: "#000",
              }),
              stroke: Stroke({
                color: "#fff",
                width: 3,
              }),
              text: name,
              offsetX: 0,
              offsetY: -15,
            });
            style.setText(text);
            style.setZIndex(3);
            return style;
        }
      };

      this.areaRegionLayer = new VectorLayer({
        source: new VectorSource(),
        zIndex: 1,
        updateWhileAnimating: true,
        updateWhileInteracting: true,
        style: styleFunction,
      });
      this.map.addLayer(this.areaRegionLayer);
    },
    showAreaRegion(area_id) {
      console.log(area_id);
      if (!this.areaRegionLayer) {
        this.initAreaRegionLayer();
      }
      this.loading = true;
      this.loadingText = "正在加载区划数据....";
      // getAreaRegion({ area_id: area_id })
      //   .then((res) => {
      //     const { data } = res;
      //     this.loading = false;
      //     var features = new GeoJSON().readFeatures(data, {
      //       // 用readFeatures方法可以自定义坐标系
      //       dataProjection: "EPSG:4326", // 设定JSON数据使用的坐标系
      //       featureProjection: "EPSG:3857", // 设定当前地图使用的feature的坐标系
      //     });
      //     this.areaRegionLayer.getSource().addFeatures(features);

      //     console.log(features);
      //     for (var i = 0; i < features.length; i++) {
      //       // var feature = features[i];
      //       const feature = features[i];
      //       var area = getArea(feature.getGeometry());

      //       let output;
      //       if (area > 10000) {
      //         output =
      //           Math.round((area / 1000000) * 100) / 100 +
      //           " " +
      //           "km<sup>2</sup>";

      //         this.area_measure = Math.round((area / 1000000) * 100) / 100;
      //       } else {
      //         output = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
      //         this.area_measure = Math.round(area * 100) / 100;
      //       }
      //       console.log(features[i].get("name") + "     面积:" + output);
      //     }

      //     var self = this;
      //     setTimeout(() => {
      //       self.map
      //         .getView()
      //         .fit(
      //           self.areaRegionLayer.getSource().getExtent(),
      //           self.map.getSize()
      //         );
      //     }, 200);
      //   })
      //   .catch(() => {
      //     this.loading = false;
      //   });
    },
    showAreaListRegion(area_ids) {
      console.log(area_ids);
      if (!this.areaRegionLayer) {
        this.initAreaRegionLayer();
      }
      this.loading = true;
      this.loadingText = "正在加载区划数据....";
      // getAreaListRegion({ area_ids: area_ids })
      //   .then((res) => {
      //     const { data } = res;
      //     this.loading = false;
      //     var features = new GeoJSON().readFeatures(data, {
      //       // 用readFeatures方法可以自定义坐标系
      //       dataProjection: "EPSG:4326", // 设定JSON数据使用的坐标系
      //       featureProjection: "EPSG:3857", // 设定当前地图使用的feature的坐标系
      //     });
      //     this.areaRegionLayer.getSource().addFeatures(features);

      //     var self = this;
      //     setTimeout(() => {
      //       self.map
      //         .getView()
      //         .fit(
      //           self.areaRegionLayer.getSource().getExtent(),
      //           self.map.getSize()
      //         );
      //     }, 200);
      //   })
      //   .catch(() => {
      //     this.loading = false;
      //   });
    },
    hideAreaRegion() {
      if (this.areaRegionLayer) {
        this.areaRegionLayer.getSource().clear();
      }
    },
    // showClusterLayer(features) {
    //   var source = new VectorSource({
    //     features: features,
    //   });

    //   var clusterSource = new Cluster({
    //     distance: 100,
    //     source: source,
    //   });

    //   const styleCache = {};
    //   var icon = getIconList()[0];
    //   console.log(icon);
    //   var self = this;

    //   const clusters = new VectorLayer({
    //     source: clusterSource,
    //     zIndex: 4,
    //     style: function (feature) {
    //       const size = feature.get("features").length;
    //       let style = styleCache[size];
    //       if (!style) {
    //         style = new Style({
    //           image: new Icon({
    //             size: icon.size,
    //             anchor: icon.anchor,
    //             src: self.getIconUrl(icon),
    //             scale: icon.scale,
    //           }),
    //           text: new Text({
    //             text: size.toString(),
    //             fill: new Fill({
    //               color: "#fff",
    //             }),
    //           }),
    //         });

    //         let offset_x = Object.prototype.hasOwnProperty.call(
    //           icon,
    //           "offset_x"
    //         )
    //           ? icon.offset_x
    //           : -1;
    //         if (offset_x == -1) {
    //           offset_x =
    //             (icon.size[0] * icon.scale) / 2 -
    //             icon.size[0] * icon.scale * icon.anchor[0];
    //         }
    //         let offset_y = Object.prototype.hasOwnProperty.call(
    //           icon,
    //           "offset_y"
    //         )
    //           ? icon.offset_y
    //           : -1;
    //         if (offset_y == -1) {
    //           offset_y = -(icon.size[1] * icon.anchor[1] * icon.scale) - 10;
    //         }
    //         // console.log(icon);
    //         var text = new Text({
    //           text: "" + size.toString() + " 台",
    //           font: "normal 14px 'Microsoft YaHei'",
    //           fill: new Fill({
    //             color: "#edffed",
    //           }),
    //           stroke: new Stroke({
    //             color: "#444444",
    //             width: 2,
    //           }),
    //           offsetX: offset_x,
    //           offsetY: offset_y,
    //         });
    //         style.setText(text);

    //         styleCache[size] = style;
    //       }
    //       return style;
    //     },
    //   });
    //   this.clusterLayer = clusters;

    //   this.map.addLayer(this.clusterLayer);
    // },
    hideClusterLayer() {
      if (this.clusterLayer) {
        this.map.removeLayer(this.clusterLayer);
      }
    },
    showHeatmapLayer(features) {
      this.heatLayer = new HeatmapLayer({
        source: new VectorSource({
          features: features,
        }),
        zIndex: 3,
        blur: 10,
        radius: 10,
      });
      this.map.addLayer(this.heatLayer);
    },
    hideHeatmapLayer() {
      if (this.heatLayer) {
        this.map.removeLayer(this.heatLayer);
      }
    },
    displayChange() {
      console.log("displayConfig -------------------displayChange");
      console.log(this.display_config);
      this.iconLayer.getSource().changed();

      if (this.areaRegionLayer) {
        this.areaRegionLayer.getSource().changed();
      }

      //显示和隐藏覆盖范围
      const features = this.iconLayer.getSource().getFeatures();
      let coverageFeatures = [];
      let iconFeatures = [];

      features.forEach((f) => {
        if (f.get("name") == "mapIconCoverage") {
          coverageFeatures.push(f);
        }
        if (f.get("name") == "mapIcon") {
          iconFeatures.push(f);
        }
      });

      coverageFeatures.forEach((f) => {
        this.iconLayer.getSource().removeFeature(f);
      });
      if (parseInt(this.display_config.alarm_show_coverage) == 1) {
        //显示
        console.log("iconFeatures:" + iconFeatures.length);

        iconFeatures.forEach((f) => {
          var id = f.getId();
          var json = JSON.parse(id);
          const coordinates = f.getGeometry().getCoordinates();
          var coordinate = transform(coordinates, "EPSG:3857", "EPSG:4326");
          var coordStr = toStringXY(coordinate, 8);
          var coverageFeature = this.generateCoverageFeature(json, coordStr);

          this.iconLayer.getSource().addFeature(coverageFeature);
        });
      }

      if (parseInt(this.display_config.alarm_coverage_data) == 0) {
        this.overlayList.forEach((item) => {
          const { overlay } = item;
          this.map.removeOverlay(overlay);
        });
      } else {
        this.overlayList.forEach((item) => {
          const { overlay, show } = item;
          this.map.removeOverlay(overlay);
          if (show) {
            this.map.addOverlay(overlay);
          }
        });
      }
    },
    changeAreaOverlay(area_ids) {
      this.overlayList.forEach((item) => {
        const { id, overlay } = item;
        this.map.removeOverlay(overlay);
        item.show = false;
        var index = area_ids.findIndex((area_id) => area_id === id);
        if (index >= 0) {
          item.show = true;
          if (parseInt(this.display_config.alarm_coverage_data) == 1) {
            this.map.addOverlay(overlay);
          }
        }
      });
    },
    showExport() {
      this.$refs.exportMapDialog.show = true;
    },
    // exportMapToImage(config) {
    //   const loading = ElLoading.service({
    //     lock: true,
    //     text: "正在导出图片...",
    //   });

    //   console.log(config);
    //   var exportOptions = {
    //     filter: function (element) {
    //       var className = element.className || "";
    //       console.log("filter:" + className);
    //       return (
    //         className.indexOf("ol-control") === -1 ||
    //         className.indexOf("ol-scale") > -1 ||
    //         (className.indexOf("ol-attribution") > -1 &&
    //           className.indexOf("ol-uncollapsible"))
    //       );
    //     },
    //   };

    //   const { dim, resolution } = config;

    //   var width = Math.round((dim[0] * resolution) / 25.4);
    //   var height = Math.round((dim[1] * resolution) / 25.4);
    //   var size = this.map.getSize();
    //   var viewResolution = this.map.getView().getResolution();

    //   exportOptions.width = width;
    //   exportOptions.height = height;
    //   var that = this;

    //   this.map.once("rendercomplete", function () {
    //     exportOptions.width = width;
    //     exportOptions.height = height;
    //     console.log(exportOptions);
    //     domtoimage
    //       .toBlob(that.map.getViewport(), exportOptions)
    //       .then(function (blob) {
    //         that.map.setSize(size);
    //         that.map.getView().setResolution(viewResolution);
    //         var date = new Date();
    //         var file_name =
    //           date.getFullYear() +
    //           "" +
    //           date.getMonth() +
    //           "" +
    //           date.getDate() +
    //           "_" +
    //           date.getHours() +
    //           "" +
    //           date.getMinutes() +
    //           "" +
    //           date.getSeconds() +
    //           ".png";

    //         loading.close();
    //         saveAs(blob, file_name);
    //       });
    //   });

    //   var printSize = [width, height];
    //   this.map.setSize(printSize);
    //   var scaling = Math.min(width / size[0], height / size[1]);
    //   this.map.getView().setResolution(viewResolution / scaling);
    // },

    //城区面积 KML
    initMeasureRegionLayer() {
      //measureRegionLayer
      // var vectorSource = new VectorSource({
      //   format: new KML({
      //     extractStyles: false, //至关重要
      //   }),
      //   projection: "EPSG:4326",
      // });
      // var that = this;

      // var styleFunction = function (feature) {
      //   let type = feature.getGeometry().getType();
      //   let style;
      //   switch (type) {
      //     default:
      //     case "MultiPolygon":
      //       style = new Style({
      //         stroke: new Stroke({
      //           color: parseInt(that.mapType) == 0 ? "#FF0000" : "#FF0000",
      //           width: 3,
      //         }),
      //       });
      //       var opacity = 0.1;
      //       var fill = new Fill({
      //         color: colorToRGBA("#FF0000", opacity),
      //       });
      //       style.setFill(fill);

      //       return style;
      //   }
      // };

      // this.measureRegionLayer = new VectorLayer({
      //   source: vectorSource,
      //   style: styleFunction,
      // });

      // this.map.addLayer(this.measureRegionLayer);
    },
    displayAreaListMeasureRegion(show) {
      if (this.measureRegionLayer) {
        this.map.removeLayer(this.measureRegionLayer);
        //  this.measureRegionLayer = undefined;
        if (show) {
          this.map.addLayer(this.measureRegionLayer);
        }
      }
    },
    showAreaListMeasureRegion(params) {
      if (!this.measureRegionLayer) {
        this.initMeasureRegionLayer();
      }

      if (this.measureRegionLayer) {
        this.measureRegionLayer.getSource().clear();
      }
      console.log(params);

      // getAreaListMeasureRegion(params).then((res) => {
      //   const { data } = res;

      //   var features = new KML({
      //     extractStyles: false, //至关重要
      //   }).readFeatures(data, {
      //     // 用readFeatures方法可以自定义坐标系
      //     dataProjection: "EPSG:4326", // 设定JSON数据使用的坐标系
      //     featureProjection: "EPSG:3857", // 设定当前地图使用的feature的坐标系
      //   });

      //   // for (var i = 0; i < features.length; i++) {
      //   // console.log("features length:" + features.length);
      //   // for (var i = 0; i < features.length; i++) {
      //   //   this.showMeasureTooltip(features[i]);
      //   // }

      //   this.measureRegionLayer.getSource().clear();
      //   this.measureRegionLayer.getSource().addFeatures(features);
      //   //  var that = this;
      //   // setTimeout(function () {
      //   //   that.mapZoomToExtent([that.measureRegionLayer]);
      //   // }, 500);
      // });
    },

    createMeasureTooltip() {
      if (this.measureTooltipElement) {
        this.measureTooltipElement.parentNode.removeChild(
          this.measureTooltipElement
        );
      }
      this.measureTooltipElement = document.createElement("div");
      this.measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
      this.measureTooltip = new Overlay({
        element: this.measureTooltipElement,
        offset: [0, -15],
        positioning: "bottom-center",
        stopEvent: false,
        insertFirst: false,
      });
    },
    showMeasureTooltip(feature) {
      var polygon = feature.getGeometry();

      let area = getArea(polygon);

      let output;
      if (area > 10000) {
        output =
          Math.round((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";

        this.area_measure = Math.round((area / 1000000) * 100) / 100;
      } else {
        output = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
        this.area_measure = Math.round(area * 100) / 100;
      }
      this.measureTooltipElement = null;

      this.createMeasureTooltip();
      this.map.addOverlay(this.measureTooltip);

      if (this.measureTooltipElement) {
        let tooltipCoord = polygon.getInteriorPoints().getCoordinates()[0];
        this.measureTooltipElement.innerHTML = output;
        this.measureTooltip.setPosition(tooltipCoord);
        this.measureTooltip.setOffset([0, -7]);
      }
    },
    showTooltip(id, tooltipCoord, text) {
      this.measureTooltipElement = null;
      this.createMeasureTooltip();

      if (this.measureTooltipElement) {
        this.measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
        this.measureTooltipElement.innerHTML = text;
        this.measureTooltip.setPosition(tooltipCoord);
        this.measureTooltip.setOffset([0, -7]);
        if (this.display_config.alarm_coverage_data == 1) {
          this.map.addOverlay(this.measureTooltip);
        }

        let exists = false;
        for (let i = 0; i < this.overlayList.lenght; i++) {
          let item = this.overlayList[i];
          if (item.id == id) {
            item.overlay = this.measureTooltip;
            exists = true;
          }
        }
        if (!exists) {
          this.overlayList.push({
            id: id,
            overlay: this.measureTooltip,
            show: true,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 100%;
}

.switch-container {
  position: absolute;
  top: 8px;
  left: 50px;
}

.menu-container {
  position: absolute;
  right: 50px;
  top: 8px;
}
</style>

<style>
.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
}

.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}

.ol-tooltip-static {
  background-color: #ffff99;
  color: black;
  font-size: 16px;
  border: 1px solid white;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 10px solid rgba(0, 0, 0, 0.5);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  content: "";
  position: absolute;
  bottom: -10px;
  margin-left: -11px;
  left: 50%;
}

.ol-tooltip-static:before {
  border-top-color: #ffff99;
}
</style>
